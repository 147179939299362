import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

export default function Slides() {
    return (
        <>
            <h1 className='py-4 pb-10 text-3xl md:text-5xl font-bold leading-tight text-darken text-center bg-white'>Відгуки Учнів</h1>
            <Swiper navigation={true}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                className="mySwiper"
                loop
            >
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FScreenshot_20241017_231936_com.instagram.android_edit_174809674799366.jpg?alt=media&token=ec57b05a-85fe-407d-9500-3ddd3556fa17" alt="" /></div></SwiperSlide>
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FScreenshot_20241017_231952_com.instagram.android_edit_174890496943104.jpg?alt=media&token=04d865f5-3cab-49c6-97db-4f2a69ed5b60" alt="" /></div></SwiperSlide>
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FScreenshot_20241017_232108.jpg?alt=media&token=884848e4-67a2-4569-b13c-a79174dc0d36" alt="" /></div></SwiperSlide>
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FScreenshot_20241017_232131.jpg?alt=media&token=3877f1f7-6dab-43a2-94f8-86a6d40128f2" alt="" /></div></SwiperSlide>
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FScreenshot_20241017_231834_com.instagram.android_edit_174857594197276.jpg?alt=media&token=fd1d03b2-8c83-4494-86d0-648135c11758" alt="" /></div></SwiperSlide>
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2Fslides%2FScreenshot_20241017_232443_com.instagram.android_edit_175081831841512.jpg?alt=media&token=7ff24ad4-41a6-4f6c-9051-e83d73e814da" alt="" /></div></SwiperSlide>
                <SwiperSlide><div className="flex items-center justify-center h-[300px] lg:h-[400px]"><img className="object-contain w-full h-full" src="https://firebasestorage.googleapis.com/v0/b/test-course-88f8a.appspot.com/o/images%2F462535788_442599368872619_994739610213255731_n.jpg?alt=media&token=35ee7772-cbf9-447a-8009-1e39f9acabe1" alt="" /></div></SwiperSlide>
            </Swiper>
        </>
    );
};